<template>
    <div class="pageloader has-background-primary is-left-to-right">
        <span class="title">Cargando</span>
    </div>
</template>

<script>
export default {
    name: 'PageLoader'
};
</script>

<style lang="scss" scoped>
</style>