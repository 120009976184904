<template>
    <section>
        <the-navbar />
        <router-view style="padding-top: 66px"></router-view>
    </section>
</template>

<script>
import TheNavbar from '@/components/shared/Layout/TheNavbar.vue';
import PageLoader from '@/components/shared/PageLoader';

export default {
    name: 'Account',
    metaInfo: {
        title: 'Cuenta',
    },
    components: {
        PageLoader,
        TheNavbar,
    },
    data() {
        return {
            displayLoader: true,
        };
    },
    computed: {
        currentToken() {
            return this.$store.state.auth.token;
        },
        perfil() {
            return this.$store.state.me.perfil;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    async mounted() {
        /* if (!this.currentToken) {
            await this.$router.push('/login');
        } else {
            this.displayLoader = true;
            try {
                const res = await this.$store.dispatch('me/get');
            } catch (err) {
                await this.$store.dispatch('auth/logout');
                await this.$router.push('/login');
            } finally {
                this.displayLoader = false;
            }
        } */
    },
};
</script>

<style>
</style>
